<template>
  <v-stepper v-model="e1">
    <v-stepper-header>
      <v-stepper-step
        :complete="e1 > 1"
        step="1"
      >
        Select Ticketing Service
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        :complete="e1 > 2"
        step="2"
      >
        Configure Profiling Hierarchy
      </v-stepper-step>
      <v-divider />
      <v-stepper-step
        :complete="e1 > 3"
        step="3"
      >
        Configure Profiling App Users and Permissions
      </v-stepper-step>
    </v-stepper-header>

    <v-stepper-items>
      <v-overlay :value="overlay">
        <v-progress-circular
          indeterminate
          size="64"
        />
      </v-overlay>
      <div class="d-flex justify-space-between mt-5 mr-5">
        <v-spacer />
        <ticketing-request-limit-popup
          :current-num="currentNum"
          :max-num="maxNum"
          :crm-id="crmId"
          @check-ticketing-request-limit="checkTicketingRequestLimit()"
        />
      </div>
      <v-stepper-content step="1">
        <v-data-table
          v-model="selected"
          :headers="ticketing.headers"
          :items="ticketingItems"
          class="elevation-0"
          :single-select="true"
          item-key="service_id"
          show-select
        />
        <v-row class="mt-2">
          <v-spacer />
          <v-col cols="2">
            <v-btn
              dark
              :disabled="(selected.length > 0) ? false : true"
              color="color_green"
              @click="e1 = 2;"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>

      <v-stepper-content step="2">
        <service-treeview
          v-if="e1 == 2"
          :selected-ticketing-service="selected[0]"
          :is-creating-profiling="true"
        />
        <v-row class="mt-4">
          <v-col cols="1">
            <v-btn
              dark
              color="warning"
              @click="close()"
            >
              Close
            </v-btn>
          </v-col>
          <v-spacer />
          <v-btn
            class="mt-3"
            text
            @click="e1 = 1"
          >
            Cancel
          </v-btn>
          <v-col cols="2">
            <v-btn
              dark
              color="color_green"
              @click="confirmProfiling()"
            >
              Continue
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
      <v-stepper-content step="3">
        <service-treeview
          v-if="e1 == 3"
          :selected-ticketing-service="selected[0]"
          :is-creating-profiling="false"
        />
        <v-row class="mt-4">
          <v-col cols="1">
            <v-btn
              dark
              color="warning"
              @click="close()"
            >
              Close
            </v-btn>
          </v-col>
          <v-spacer />
          <v-btn
            class="mt-3"
            text
            @click="e1 = 2"
          >
            Cancel
          </v-btn>
          <v-col cols="2">
            <v-btn
              dark
              color="color_green"
              @click="e1 = 1"
            >
              Confirm
            </v-btn>
          </v-col>
        </v-row>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { findItemNested, modifiedHierarchyItemsOnly, setOldNameToEditedItems, deletedHierarchyItemsOnly, createdHierarchyItemsOnly } from '@/utils.js';

export default {
  components: {
    ServiceTreeview: () => import('@/components/services/ServiceTreeview'),
    TicketingRequestLimitPopup: () => import('@/components/customer/TicketingRequestLimitPopup')
  },
  props: {
    crmId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentNum: 0,
      maxNum: 0,
      selected: [],
      ticketingItems: [],
      hierarchyItemsEdited: [],
      hierarchyItemsCreated: [],
      hierarchyItemsDeleted: [],
      overlay: false,
      e1: 1
    };
  },
  computed: {
    ...mapState(['policy', 'services', 'ticketing'])
  },
  async mounted() {
    this.services.linkedServices.forEach(item => {
      if (item.service_type == 'TICKET') {
        this.ticketingItems.push(item);
      }
    });
    this.e1 = 1;
    await this.checkTicketingRequestLimit();
  },
  methods: {
    ...mapActions(['createProfilingTicketingServiceAction', 'storeGetProfilingTicketingService', 'editProfilingTicketingServiceAction', 'deleteProfilingTicketingServiceAction', 'listTicketingRequestLimitAction']),
    close() {
      this.e1 = 1;
      this.selected = [];
    },
    async confirmProfiling() {
      findItemNested(
        this.services.hierarchyItems,
        'children',
        this.services.hierarchyItemsLinear
      );
      this.services.hierarchyItemsLinear.forEach(item => {
        item.service_id = this.selected[0].service_id;
      });

      if (this.ticketing.storeProfilingTicketing.hierarchy.length > 0) {
        this.hierarchyItemsDeleted = { profiling: [], hierarchy: [] };
        this.hierarchyItemsEdited = { profiling: [], hierarchy: [] };
        this.hierarchyItemsCreated = { profiling: [], hierarchy: [] };

        this.hierarchyItemsDeleted.hierarchy = deletedHierarchyItemsOnly(this.services.hierarchyItemsLinear, this.ticketing.storeProfilingTicketing.hierarchy);

        const editedProfilingItemsOnly = modifiedHierarchyItemsOnly(this.services.hierarchyItemsLinear, this.ticketing.storeProfilingTicketing.hierarchy);

        setOldNameToEditedItems(editedProfilingItemsOnly, this.ticketing.storeProfilingTicketing.hierarchy);

        createdHierarchyItemsOnly(editedProfilingItemsOnly, this.hierarchyItemsEdited.hierarchy, this.hierarchyItemsCreated.hierarchy);

        if (this.hierarchyItemsEdited.hierarchy.length > 0) {
          this.hierarchyItemsEdited.hierarchy.map(item => {
            delete item.node_old_name;
          });
          this.overlay = true;
          await this.editProfilingTicketingServiceAction(this.hierarchyItemsEdited);
          this.overlay = false;
        }

        if (this.hierarchyItemsCreated.hierarchy.length > 0) {
          this.overlay = true;
          await this.createProfilingTicketingServiceAction(this.hierarchyItemsCreated);
          this.overlay = false;
        }

        if (this.hierarchyItemsDeleted.hierarchy.length > 0) {
          this.overlay = true;
          await this.deleteProfilingTicketingServiceAction(this.hierarchyItemsDeleted);
          this.overlay = false;
        }

      } else {
        const data = { profiling: [], hierarchy: [] };
        this.services.hierarchyItemsLinear.forEach(hierarchy => {
          data.hierarchy.push(hierarchy);
        });
        this.overlay = true;
        await this.createProfilingTicketingServiceAction(data);
        this.overlay = false;
      }

      this.hierarchyItemsDeleted = [];
      this.hierarchyItemsCreated = [];
      this.hierarchyItemsEdited = [];
      this.e1 = 3;
    },
    async checkTicketingRequestLimit() {
      this.overlay = true;
      await this.listTicketingRequestLimitAction(this.crmId);
      const findCrm = this.ticketing.ticketingRequestLimit.find(e => e.crm_id === this.crmId);
      this.currentNum = findCrm ? findCrm.request_count : 0;
      this.maxNum = findCrm ? findCrm.request_limit : 0;
      this.overlay = false;
    }
  }
};
</script>

<style scoped>
.v-input--selection-controls /deep/ {
  margin-top: 0 !important;
}
</style>
